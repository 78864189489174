import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';



class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const abusiveid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
            abusiveid: abusiveid,
			abusive_name:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
		};
		var qs = require('qs');
		var catid = this.state.abusiveid;

		axios.get(apiUrl+"AbusiveManagement/listdetail?id="+catid).then(res => {
			this.setState({abusive_name:res.data.abusivelist.abusive_word});
			if(res.data.abusivelist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			
			
		}); 

		
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);

    }

    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      		
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}
	
	handleChangeSpecialist = selectedSpecialist => {

		this.setState({ selectedSpecialist});
		this.setState({ specialistvalue : selectedSpecialist.value });
 }


	componentDidMount() {
		document.title = PageTitle('Abusive Word Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					abusive_name: formPayload.abusive_name,
					status:  status,
					id: formPayload.abusiveid,
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"AbusiveManagement/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html(this.props.history.push('/abusivewords'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {abusive_name} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!abusive_name) {
			formIsValid = false;
			$('.errorabusive_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(abusive_name){
			$('.errorabusive_name').html('');
		}


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

  render() {


	


  	  const {selectedOption} = this.state;

		


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="abusiveword" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="content-wrapper">
				<a href="/abusivewords" >Back</a>   
			</div>
			<div className="title">
				<h4>Abusive Word Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label> Abusive Word<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="abusive_name" onChange={this.handleInputChange} className="form-control" value={this.state.abusive_name} />
					<div className="errorabusive_name"></div>
				</div>

				
			</div>
                  
			<div className="form-right">
				
               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default (withRouter(Edit));