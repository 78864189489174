import axios from 'axios';
import { apiUrl} from'../Config/Config';

const membership_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"membership/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['id'], label: planlist[i]['title'] };
            }
            
        }       
    });
    return planLists;
}

const categories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

export const lang = {

    common: {
        title:'Petcare',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        productType_option:[
            { value: '1', label: 'Normal Product' },
            { value: '2', label: 'Shopping Product' },
            { value: '3', label: 'Regular Product' },
        ],
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
        membership_plan_option: membership_plan(),
        categories_option: categories(),
        categories_option1: categories1(),
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
        user_field_options :[
            { value: 'pets', label: 'Pets' },
            { value: 'invited', label: 'Invited' },
            { value: 'invitedfriends', label: 'Invited friends' },
            { value: 'order', label: 'Order' },
            { value: 'viewmap', label: 'View Map' },
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },
            { value: 'membership', label: 'Membership'},   
            { value: 'invite_friends', label: 'Invite friends'},  
            { value: 'paymentcard', label: 'payment Method'},  
                       
        ],
        vet_field_options :[
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },
            { value: 'general', label: 'General' },
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'recommendedhistory', label: 'Recommended history' },
            { value: 'mybagorder', label: 'Mybag Orders' },
            { value: 'mybag', label: 'Mybag list' },
            { value: 'technician', label: 'Technicians' },
            { value: 'refillrequest', label: 'Refill request' },
            { value: 'recommendedproduct', label: 'Recommended product' },
            { value: 'revenue', label: 'Revenue Management' },
            { value: 'account_management', label: 'Account Management' },

        ],
        tech_field_options :[       
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'assignedvet', label: 'Aassigned vet' },
            { value: 'pending', label: 'Pending' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'completed', label: 'Completed' },
            { value: 'general', label: 'General' },
          
        ],
        company_field_options :[
            // { value: 'home', label: 'Home' },           
            // { value: 'membership', label: 'Membership' },
            // { value: 'memberlist', label: 'Family Members' },
            // { value: 'general', label: 'General' },
            // { value: 'viewmap', label: 'View Map' },            
            // { value: 'schedule', label: 'My Schedule' },          
            // { value: 'pets', label: 'Pets' },
            
            // { value: 'mybag', label: 'Mybag list' },
            // { value: 'mybagorder', label: 'Mybag Orders' },
            // { value: 'refillrequest', label: 'Refill request' },
            // { value: 'assignedvet', label: 'Aassigned vet' },
            // { value: 'recommendedproduct', label: 'Recommended product' },

            { value: 'recommendedhistory', label: 'Recommended history' },
            { value: 'users', label: 'User list' },
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'accepted', label: 'Accepted' },
            { value: 'pending', label: 'Pending' },           
            { value: 'completed', label: 'Completed'},           
            { value: 'vet', label: 'Vet list'},
            { value: 'technician', label:'Technicians'},
            { value: 'order', label:'Order'},
            { value: 'manage_user_roles', label:'Manage User Roles'},

           
        ],
        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        module_option:[
            { value: 'module1', label: 'Module1 (Vets Listing)' },
            { value: 'module2', label: 'Module2 (Product Listing)' },
            { value: 'module4', label: 'Module4 (Ebag Product)' },
        ],
        ads_field_options :[

            // { value: 'home', label: 'Home' },

            // { value: 'pending', label: 'Pending' },

            // { value: 'accepted', label: 'Accepted' },

            // { value: 'completed', label: 'Completed' },

            // { value: 'order', label: 'Order' },

            { value: 'maintab', label: 'Home' },
            { value: 'vets', label: 'Vets' },
            { value: 'membership', label: 'Membership' },
            { value: 'store', label: 'Store' },
            { value: 'myBag', label: 'My Bag' },
        ],
        advertisement_linktype:[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
            { value: 'offer', label: 'Offers' },
        ],
        offer_linktype:[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
            { value: 'product', label: 'Product' },
        ],
        setting_font_varient:[
            // { value: 'primay', label: 'Primay' },
            { value: 'mainTab', label: 'MainTab'},
            { value: 'sideMenu', label: 'SideMenu'},
            { value: 'title', label: 'Title' },
            { value: 'subTitle', label: 'SubTitle' },
            { value: 'button', label: 'Button' },
            { value: 'smButton', label: 'SmallButton' },
            { value: 'lgButton', label: 'LargeButton' },
        ],
    },
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};


